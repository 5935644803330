import React from "react"

import SEO from "../../../components/seo"
import Header from "../../../components/header"
import InfoModal from "../../../components/info-modal"
import QuestionModal from "../../../components/question-modal"

import { Vector3 } from "three"
import { ImagePanorama, Viewer, Infospot } from "panolens"
import { navigate } from "gatsby"
import LoadingBar from "../../../components/loading-bar"
import PanoContainer from "../../../components/pano-container"
import ToolBar from "../../../components/tool-bar"
import { Howl } from "howler"

// Custom Icons
import QuestionMark from "../../../../static/icons/question-mark-white.png"
import EmergencyMark from "../../../../static/icons/emergency-mark-white.png"
import ArrowMark from "../../../../static/icons/arrow-mark-white.png"

export default class DisposableCrown extends React.Component {
  constructor() {
    super()
    this.state = {
      currentQuestion: "",
      infoModalIsOpen: false,
      questionModalIsOpen: false,
      progress: 0,
    }
  }

  setPositionToTarget(target, viewer) {
    const { x, y, z } = target.normalize()
    viewer.camera.position.set(x, -y, -z)
  }

  createQuestionInfoSpot(question, position) {
    const questionInfoSpot = new Infospot(300, QuestionMark, false)
    questionInfoSpot.position.set(position.x, position.y, position.z)
    questionInfoSpot.addHoverText(question)
    questionInfoSpot.addEventListener("click", () => {
      this.setState({
        currentQuestion: question,
        questionModalIsOpen: true,
      })
    })

    return questionInfoSpot
  }

  componentDidMount() {
    // Creating the different Panorama objects
    const mainPanorama = new ImagePanorama(
      "/departments/disposable-crown/panorama.png"
    )

    const crownPanorama = new ImagePanorama(
      "/departments/disposable-crown/crown-panorama.png"
    )

    // Progress Events
    mainPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    crownPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    mainPanorama.setLinkingImage(ArrowMark, 350)
    crownPanorama.setLinkingImage(ArrowMark, 350)

    // Linking
    mainPanorama.link(crownPanorama, new Vector3(4961.97, 436.7, -331.25))
    crownPanorama.link(mainPanorama, new Vector3(4787.83, -1028.97, 982.95))

    // Create the InfoSpot to Go to the next department
    const doorInfoSpot = new Infospot(350, ArrowMark, false)
    doorInfoSpot.position.set(-4985.47, 118.39, 188.54)
    doorInfoSpot.addHoverText("Go to the next department")
    doorInfoSpot.addEventListener("click", () => {
      navigate("/departments/the-altar-of-the-i/")
    })

    const emergencyExitSpot = new Infospot(350, EmergencyMark, false)
    emergencyExitSpot.position.set(-4190.78, 2025.53, 1811.05)
    emergencyExitSpot.addHoverText("Go to the emergency exit")
    emergencyExitSpot.addEventListener("click", () => {
      navigate("/departments/the-emergency-exit/")
    })

    // Crown Question InfoSpot
    const crownQuestionSpot = this.createQuestionInfoSpot(
      "Have I ever worn a crown on my birthday?",
      new Vector3(3838.87, 427.77, 3162.46)
    )

    const ruleBirthdayQuestionSpot = this.createQuestionInfoSpot(
      "Do I know how to rule my birthday?",
      new Vector3(4162.84, -1432.67, -2359.77)
    )

    const monarchQuestionSpot = this.createQuestionInfoSpot(
      "I feel like a monarch on my birthday",
      new Vector3(3719.72, 613.14, -3274.78)
    )

    // Add the Spots to the Main Panorama
    mainPanorama.add(doorInfoSpot)
    mainPanorama.add(emergencyExitSpot)
    mainPanorama.add(crownQuestionSpot)
    mainPanorama.add(ruleBirthdayQuestionSpot)

    // Add the Spots to the Crown Panorama
    crownPanorama.add(monarchQuestionSpot)

    const viewer = new Viewer({
      cameraFov: 90,
      container: document.getElementById("pano-container"),
      controlBar: false,
      output: "console",
      autoHideInfospot: false,
    })

    // Set initial Camera position
    this.setPositionToTarget(new Vector3(-4985.47, 118.39, 188.54), viewer)

    viewer.add(mainPanorama)
    viewer.add(crownPanorama)

    // Setup the new Howl.
    this.sound = new Howl({
      src: ["/departments/disposable-crown/disposable-crown.mp3"],
    })

    // Play the sound.
    this.sound.play()

    // Fade In
    this.sound.fade(0.0, 0.5, 2000)
  }

  componentWillUnmount() {
    this.sound.fade(0.5, 0.0, 1000)
  }

  loadingProgress(progressEvent) {
    var loaded = progressEvent.progress.loaded
    var total = progressEvent.progress.total
    var completedProgress = 365
    var percentage = Math.round((loaded / total) * completedProgress)

    this.setState({
      progress: percentage,
    })
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
    })
  }

  closeQuestionModal = () => {
    this.setState({
      questionModalIsOpen: false,
    })
  }

  render() {
    return (
      <>
        <SEO title="Disposable Crown" />
        <Header menuIsVisible={false} />
        <PanoContainer />
        <ToolBar
          onInfoClick={() =>
            this.setState({
              infoModalIsOpen: true,
            })
          }
          muteSound={() => this.sound.mute(!this.sound.mute())}
        />
        <LoadingBar progress={this.state.progress} />
        <InfoModal
          isOpen={this.state.infoModalIsOpen}
          onRequestClose={this.closeInfoModal}
          title="Disposable Crown"
        >
          <p>
            When I turned four, I was crowned a (more or less) independent
            individual. My crown, made of pink cardboard and blue and purple
            crepe paper ribbons, was stapled around my head. It fitted like a
            dream.
          </p>
          <p>
            An individual becomes a protagonist on a birthday. As the crowned
            one, you are the point of attraction to the eyes of your classmates.
            Your individuality is once again outlined with a big black marker.
            Look at that autonomous entity! They sing to you; you give them
            sweets. They say ‘congratulations’ and they say ‘thank you’. This
            mutual act of appreciation for the existence of yourself and the
            existence of others is a moral of children’s birthday celebration in
            infant school. Today you are the sovereign, however you know that
            tomorrow there will be another monarch.
          </p>
          <p>
            As it seems widely accepted to title someone or yourself the
            ‘birthday queen or king’ for this one specific day in the year, what
            does it mean? How do you rule? How does the crown fit?
          </p>
        </InfoModal>
        <QuestionModal
          isOpen={this.state.questionModalIsOpen}
          onRequestClose={this.closeQuestionModal}
          question={this.state.currentQuestion}
        ></QuestionModal>
      </>
    )
  }
}
